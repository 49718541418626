import React from "react"
import { graphql, StaticQuery } from "gatsby"
// @ts-ignore
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Post } from "../templates/types"
import { Comic } from "../components/Comic"
import { BonusComicContainer } from "../components/BonusComicContainer"

const IndexPage = ({ data, location }: any) => {
    const siteTitle = data.site.siteMetadata?.title || ``
    const mostRecentPost: Post = data.allMarkdownRemark.nodes[0]
    mostRecentPost.frontmatter.title = "Latest Comic"

    if (!mostRecentPost) {
        return (
            <Layout location={location} title={siteTitle}>
                <SEO title="Zip Freeman" />
                <Bio />
                <p>No comics found</p>
            </Layout>
        )
    }

    const previousPost = data.allMarkdownRemark.nodes[1] || null

    return (
        <>
            <Layout location={location} title={siteTitle}>
                <SEO
                    title={mostRecentPost.frontmatter.title}
                    description={
                        mostRecentPost.frontmatter.description ||
                        mostRecentPost.excerpt
                    }
                />

                <Comic
                    post={mostRecentPost}
                    previous={previousPost}
                    fields={{ slug: mostRecentPost.fields?.slug || "/" }}
                />
                <BonusComicContainer
                    bonus={mostRecentPost?.frontmatter?.bonus}
                />
            </Layout>
        </>
    )
}

export default function IndexPageQueryRender(props: any) {
    return (
        <StaticQuery
            query={graphql`
                query {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                    allMarkdownRemark(
                        sort: { fields: [frontmatter___date], order: DESC }
                    ) {
                        nodes {
                            fields {
                                slug
                            }
                            frontmatter {
                                date(formatString: "MMMM DD, YYYY")
                                title
                                description
                                tags
                                bonus {
                                    childImageSharp {
                                        fluid(maxWidth: 800, quality: 85) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                                bonusStatic
                            }
                            html
                        }
                    }
                }
            `}
            render={data => <IndexPage data={data} {...props} />}
        />
    )
}
