import React from "react"
import { BonusImage } from "../templates/types"
import Img from "gatsby-image"

interface Props {
    bonus?: BonusImage | string
}

export const BonusComicContainer = ({ bonus }: Props): JSX.Element | null => {
    const image = bonus?.childImageSharp?.fluid || bonus

    return image ? (
        <>
            <a href="#bonus" />
            <div id="bonus">
                {typeof bonus === "string" ? (
                    <img
                        src={bonus}
                        alt={"bonus image"}
                        style={{ width: "100%", marginTop: "20px" }}
                    />
                ) : (
                    <Img fluid={image} />
                )}
            </div>
        </>
    ) : null
}
