import { Link } from "gatsby"
import React from "react"
import { Post } from "../templates/types"
import {
    AiOutlineDoubleLeft,
    AiOutlineLeft,
    AiOutlineRight,
    AiOutlineDoubleRight,
} from "react-icons/ai"
import { primaryColor } from "./layout"

const darkButtonStyle = {
    backgroundColor: primaryColor,
    color: "white",
    borderRadius: "6px",
    padding: "3px",
    width: "50px",
    height: "25px",
}

export const Li = ({
    style = {},
    children,
}: {
    style: React.CSSProperties
    children: React.ReactNode
}) => (
    <li style={{ fontSize: "var(--fontSize-3)", width: "33%", ...style }}>
        {children}
    </li>
)

interface Props {
    previous?: Partial<Post>
    next?: Partial<Post>
    style?: any
}
export const Navigation = ({ previous, next, style }: Props) => (
    <nav className="blog-post-nav">
        <ul
            style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
                marginRight: "1rem",
                marginLeft: "1rem",
                columns: 3,
                ...style,
            }}
        >
            <Li style={{ textAlign: "left", flex: 1 }}>
                {previous?.fields && (
                    <Link
                        to={"/1-noseman-resume"}
                        rel={"first"}
                        title="go to first comic"
                    >
                        <AiOutlineDoubleLeft />
                    </Link>
                )}
            </Li>
            <Li style={{ textAlign: "left", flex: 1 }}>
                {previous?.fields && (
                    <Link
                        to={previous.fields.slug}
                        rel={"prev"}
                        title="go to older"
                    >
                        <AiOutlineLeft style={darkButtonStyle} />
                    </Link>
                )}
            </Li>
            <Li style={{ textAlign: "center", flex: 1 }}>
                <Link to="/list" rel="list" title="view list of all comics">
                    list
                </Link>
            </Li>
            <Li style={{ textAlign: "right", flex: 1 }}>
                {next?.fields && (
                    <Link to={next.fields.slug} rel={"next"} title="go to next">
                        <AiOutlineRight style={darkButtonStyle} />
                    </Link>
                )}
            </Li>
            <Li style={{ textAlign: "right", flex: 1 }}>
                {next?.fields && (
                    <Link to={"/"} rel={"latest"} title="go to latest">
                        <AiOutlineDoubleRight />
                    </Link>
                )}
            </Li>
        </ul>
    </nav>
)
