import { Link } from "gatsby"
import React from "react"
import { Post } from "../templates/types"
import { Navigation } from "./Navigation"

interface ComicProps {
    post: Post
    previous?: Partial<Post>
    next?: Partial<Post>
    fields: { slug: string }
}

export const Comic = ({ post, previous, next, fields }: ComicProps) => {
    const title = post?.frontmatter?.title || ""
    const date = post?.frontmatter?.date || ""

    return (
        <>
            <Navigation
                previous={previous}
                next={next}
                style={{ height: "30px" }}
            />
            <hr />
            <article
                className="blog-post"
                itemScope
                itemType="http://schema.org/Article"
            >
                <header
                    style={{
                        display: "flex",
                        marginTop: "1em",
                        marginBottom: 0,
                        margin: "1em 1rem 0 1rem",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <h2
                        itemProp="headline"
                        style={{ margin: 0, fontSize: "var(--fontSize-1)" }}
                    >
                        {fields?.slug ? (
                            <Link to={fields.slug}>{title}</Link>
                        ) : (
                            title
                        )}
                    </h2>

                    <small>{date}</small>
                </header>
                <section
                    className="nested-image-container"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                    itemProp="articleBody"
                />
                <hr />
            </article>
            <Navigation previous={previous} next={next} />
        </>
    )
}
